import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Alert } from '@mui/material';
import { isEmpty, isEqual, omit } from 'lodash';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import { Loading } from 'components/shared';
import Logger from 'lib/Logger';

import {
  fetchBehavioralDiscount,
  createBehavioralDiscount,
  updateBehavioralDiscount,
  fetchFutureMenus,
  fetchMenuMeals,
  fetchMealBadges,
  fetchProductTypes,
} from 'services/behavioralDiscountTool';

import { cleanArray } from 'lib/utils';
import { initialState } from './reducer';
import ConfirmationDialog from './ConfirmationDialog';
import MealBadgeSection from './MealBadgeSection';
import DisclaimerSection from './DisclaimerSection';
import BannerSection from './BannerSection';
import TriggerSection from './TriggerSection';
import RewardSection from './RewardSection';
import SaveOrCancelButtons from './SaveOrCancelButtons';
import BaseAttributeSection from './BaseAttributeSection';
import { convertFormStateToParams } from './serializer';

const BehavioralDiscountTool = ({ classes }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const [behavioralDiscount, setBehavioralDiscount] = useState({});
  const [fetchingBehavioralDiscount, setFetchingBehavioralDiscount] = useState(false);
  const [filename, setFilename] = useState('');
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [userCount, setUserCount] = useState(0);

  // There may be some opportunity to consolidate/remove these
  const [newServiceType, setNewServiceType] = useState('');
  const [newDiscountFrequency, setNewDiscountFrequency] = useState('');

  const { behavioralDiscountId } = useParams();

  useEffect(() => {
    if (behavioralDiscountId && isEmpty(behavioralDiscount)) {
      setFetchingBehavioralDiscount(true);
      fetchBehavioralDiscount(behavioralDiscountId)
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          setUserCount(response.data.userCount);
          setFetchingBehavioralDiscount(false);
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          setFetchingBehavioralDiscount(false);
          Logger.error(e);
        });
    }
  }, [behavioralDiscountId, actions, behavioralDiscount]);

  useEffect(() => {
    if (!isEmpty(behavioralDiscount)) {
      actions.setFormStateFromBehavioralDiscount(behavioralDiscount);
    }
  }, [behavioralDiscount, actions]);

  useEffect(() => {
    if (!formState.formData.menus.length) {
      fetchFutureMenus()
        .then((response) => {
          actions.setFormData('menus', response.data.menus);
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          Logger.error(e);
        });
    }
  }, [formState.formData.menus, actions]);

  useEffect(() => {
    if (formState.menuId && !formState.formData.meals.length) {
      fetchMenuMeals(formState.menuId)
        .then((response) => {
          actions.setFormData('meals', response.data.meals);
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          Logger.error(e);
        });
    }
  }, [formState.formData.meals, formState.menuId, actions]);

  useEffect(() => {
    if (formState.discountDuration === 'recurring' && !formState.formData.productTypes.length) {
      fetchProductTypes()
        .then((response) => {
          actions.setFormData('productTypes', response.data.productTypes);
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          Logger.error(e);
        });
    }
  }, [formState.discountDuration, formState.formData.productTypes, actions]);

  useEffect(() => {
    const filterMealsByServiceType = async () => {
      const mealsByServiceType = await formState.formData.meals.filter(
        (meal) => meal.serviceType === formState.serviceType
      );
      setFilteredMeals(mealsByServiceType);
    };

    filterMealsByServiceType();
  }, [formState.formData.meals, formState.serviceType]);

  useEffect(() => {
    if (
      formState.formData.mealBadges.length === 0 &&
      (formState.discountTarget === 'meal' || formState.discountTarget === 'product_type')
    ) {
      fetchMealBadges()
        .then((response) => {
          actions.setFormData('mealBadges', response.data.mealBadges);
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          Logger.error(e);
        });
    }
  }, [formState.formData.mealBadges, formState.discountTarget, actions]);

  useEffect(() => {
    if (formState.photoFile) {
      setPreviewImage(URL.createObjectURL(formState.photoFile));
      setFilename(formState.photoFile.name);
    } else if (formState.photo.url) {
      setPreviewImage(formState.photo.url);
      setFilename(formState.photo.filename);
    } else {
      setPreviewImage(null);
      setFilename(null);
    }
  }, [formState.photo, formState.photoFile]);

  if (fetchingBehavioralDiscount || formState.formValidation.posting) {
    return <Loading />;
  }

  const resetDiscountFields = () => {
    actions.setFormState('menuId', null);
    actions.setFormState('triggerType', 'basket');
    actions.setFormState('discountTarget', 'basket');
    actions.setFormState('triggerMenuSelectionIds', []);
    actions.setFormState('rewardMenuSelectionIds', []);
    actions.setFormState('rewardProductTypeIds', []);
    actions.setFormState('mealBadgeId', null);
  };

  const onServiceTypeChange = () => {
    setShowConfirmationDialog(false);
    actions.changeServiceType(newServiceType);
    setNewServiceType('');
  };

  const onDiscountFrequencyChange = () => {
    resetDiscountFields();
    setShowConfirmationDialog(false);
    actions.setFormState('discountDuration', newDiscountFrequency);
    setNewDiscountFrequency('');
  };

  const onCancelConfirmationDialogChange = () => {
    setShowConfirmationDialog(false);
  };

  const onMenuChange = (newMenuId) => {
    actions.setFormState('triggerMenuSelectionIds', []);
    actions.setFormState('rewardMenuSelectionIds', []);
    actions.setFormState('rewardProductTypeIds', []);
    actions.setFormState('menuId', newMenuId);

    fetchMenuMeals(newMenuId)
      .then((response) => {
        actions.setFormData('meals', response.data.meals);
      })
      .catch((e) => {
        actions.setFormValidation('apiErrorMessage', e.response.data.error);
        Logger.error(e);
      });
  };

  const renderSuccessMessages = () => {
    return formState.formValidation.successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderErrorMessage = () => {
    return (
      <Alert severity="error" variant="filled">
        {formState.formValidation.apiErrorMessage}
      </Alert>
    );
  };

  const renderConfirmationDialog = () => {
    let text;

    if (newServiceType) {
      text = `Switching to <${newServiceType.toUpperCase()}> will reset all of your prior selections.
        Are you sure you would like to change service type? Changes will only take effect once saved.`;

      return (
        <ConfirmationDialog
          confirmationText="Reset form"
          open={showConfirmationDialog}
          onConfirm={onServiceTypeChange}
          onCancel={onCancelConfirmationDialogChange}
          text={text}
          title="Reset Form?"
        />
      );
    }

    if (newDiscountFrequency) {
      text = `Products have been selected for '${formState.discountDuration}' discount frequency.
        You will need to select new products if you change the discount frequency.`;

      return (
        <ConfirmationDialog
          confirmationText="Remove Products"
          open={showConfirmationDialog}
          onConfirm={onDiscountFrequencyChange}
          onCancel={onCancelConfirmationDialogChange}
          text={text}
          title="Remove All Selected Products?"
        />
      );
    }

    return null;
  };

  const handleServiceTypeChange = (newType) => {
    if (formState.serviceType === newType) return;

    if (
      isEqual(
        omit(formState, [
          'serviceType',
          'bannerBackgroundColor',
          'bannerTextColor',
          'bannerTagBackgroundColor',
          'bannerTagTextColor',
          'formData[menus]',
        ]),
        omit(initialState, [
          'serviceType',
          'bannerBackgroundColor',
          'bannerTextColor',
          'bannerTagBackgroundColor',
          'bannerTagTextColor',
          'formData[menus]',
        ])
      )
    ) {
      actions.changeServiceType(newType);
    } else {
      setNewServiceType(newType);
      setShowConfirmationDialog(true);
    }
  };

  const handleDiscountFrequencyChange = (newFrequency) => {
    if (formState.discountDuration === newFrequency) return;

    if (
      formState.triggerMenuSelectionIds.length === 0 &&
      formState.rewardMenuSelectionIds.length === 0 &&
      formState.rewardProductTypeIds.length === 0
    ) {
      actions.setFormState('discountDuration', newFrequency);
    } else {
      setNewDiscountFrequency(newFrequency);
      setShowConfirmationDialog(true);
    }
  };

  const handleFormSave = () => {
    actions.setFormValidation('apiErrorMessage', '');
    actions.setFormValidation('posting', true);
    if (behavioralDiscountId) {
      updateBehavioralDiscount(convertFormStateToParams(formState, behavioralDiscountId))
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          actions.setFormValidation('posting', false);
          actions.setFormValidation('success', true);
          actions.setFormValidation(
            'successMessages',
            cleanArray(['Behavioral Discount Updated!'])
          );
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          actions.setFormValidation('posting', false);
          actions.setFormValidation('success', false);
        });
    } else {
      createBehavioralDiscount(convertFormStateToParams(formState))
        .then((response) => {
          setBehavioralDiscount(response.data.behavioralDiscount);
          actions.setFormValidation('posting', false);
          actions.setFormValidation('success', true);
          actions.setFormValidation(
            'successMessages',
            cleanArray(['Behavioral Discount Created!'])
          );
        })
        .catch((e) => {
          actions.setFormValidation('apiErrorMessage', e.response.data.error);
          actions.setFormValidation('posting', false);
          actions.setFormValidation('success', false);
        });
    }
  };

  return (
    <div>
      {renderConfirmationDialog()}
      {formState.formValidation.success && renderSuccessMessages()}
      {formState.formValidation.apiErrorMessage.length > 0 && renderErrorMessage()}
      <BaseAttributeSection
        classes={classes}
        handleDiscountFrequencyChange={handleDiscountFrequencyChange}
        handleServiceTypeChange={handleServiceTypeChange}
        onMenuChange={onMenuChange}
        userCount={userCount}
      />
      <BannerSection classes={classes} previewImage={previewImage} filename={filename} />
      <DisclaimerSection classes={classes} />
      <TriggerSection classes={classes} filteredMeals={filteredMeals} />
      <RewardSection classes={classes} filteredMeals={filteredMeals} />
      <MealBadgeSection classes={classes} />
      <SaveOrCancelButtons
        behavioralDiscount={behavioralDiscount}
        behavioralDiscountId={behavioralDiscountId}
        classes={classes}
        handleFormSave={handleFormSave}
      />
    </div>
  );
};

BehavioralDiscountTool.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BehavioralDiscountTool;
