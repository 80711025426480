import { LIGHT_BLACK, LIGHT_GREEN } from 'lib/constants';
import { disclaimer, dashboardTagText } from 'lib/helpers/behavioralDiscountTool/defaultTextHelper';
import { defaultTemplates } from 'lib/helpers/behavioralDiscountTool/bannerHelper';
import { convertBehavioralDiscountToFormState } from './serializer';

export const initialState = {
  bannerBackgroundColor: LIGHT_GREEN,
  bannerExpiresInWeeks: '',
  bannerTextColor: LIGHT_BLACK,
  bannerTagBackgroundColor: LIGHT_BLACK,
  bannerTagTextColor: LIGHT_GREEN,
  dashboardTagText,
  description: '',
  disclaimer,
  discountDuration: 'menu',
  discountTarget: 'basket',
  discountType: 'cents',
  durationInWeeks: 1,
  eligibleOrderableUnits: '',
  mealBadgeId: null,
  menuId: null,
  maxOrderableUnits: 0,
  name: '',
  photo: {},
  photoFile: null,
  promotionText: '',
  rewardMenuSelectionIds: [],
  rewardProductTypeIds: [],
  serviceType: 'core',
  targetValueCents: 0,
  triggerMenuSelectionIds: [],
  triggerType: 'basket',
  valueCents: 0,
  valuePercent: 0,

  // This data is not submitted as part of the form but given CX's small footprint on this application,
  //  opted to not create additional, specific, providers / state management for these.
  formData: {
    menus: [],
    meals: [],
    mealBadges: [],
    productTypes: [],
  },

  // These params are not sent to the backend, instead, they are extracted in the serializer and used in some sub-components.
  // This approach helps sidestep the need to pass props.
  formValidation: {
    apiErrorMessage: '',
    errors: '',
    posting: false,
    success: false,
    successMessages: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.field]: action.value,
        },
      };
    case 'SET_FORM_VALIDATION':
      return {
        ...state,
        formValidation: {
          ...state.formValidation,
          [action.field]: action.value,
        },
      };
    case 'SET_FORM_STATE_FROM_BEHAVIORAL_DISCOUNT':
      return {
        ...state,
        ...convertBehavioralDiscountToFormState(action.behavioralDiscount),
      };
    case 'REVERT_FORM':
      return {
        ...initialState,
        ...convertBehavioralDiscountToFormState(action.behavioralDiscount),
      };
    case 'RESET_FORM':
      return {
        ...initialState,
      };
    case 'CHANGE_SERVICE_TYPE': {
      const {
        bannerBackgroundColor,
        bannerTagTextColor,
        bannerTagBackgroundColor,
        bannerTextColor,
      } = defaultTemplates[action.newServiceType];
      return {
        ...initialState,
        serviceType: action.newServiceType,
        bannerBackgroundColor,
        bannerTagTextColor,
        bannerTagBackgroundColor,
        bannerTextColor,
      };
    }
    default:
      return state;
  }
};
