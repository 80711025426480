import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useContext } from 'react';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import PropTypes from 'prop-types';

const BaseAttributeSection = ({
  classes,
  handleServiceTypeChange,
  handleDiscountFrequencyChange,
  onMenuChange,
  userCount,
}) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const formattedMenu = (endsAt) => {
    return new Date(endsAt).toDateString();
  };

  return (
    <div className={classes.root}>
      <h5 className={classes.header}>Create a Behavioral Discount</h5>
      <TextField
        fullWidth
        label="Discount Name"
        variant="outlined"
        value={formState.name}
        onChange={(e) => actions.setFormState('name', e.target.value)}
      />
      <Grid item sm={2} xs={4} className={classes.serviceType}>
        <div className={classes.label}>Service</div>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={formState.serviceType}
          exclusive
          onChange={(e) => handleServiceTypeChange(e.target.value)}
          aria-label="service"
          size="small"
          sx={{ width: 1 }}
        >
          <ToggleButton value="core" sx={{ width: 1 / 4 }}>
            CORE
          </ToggleButton>
          <ToggleButton value="family" sx={{ width: 1 / 4 }}>
            FAMILY
          </ToggleButton>
          <ToggleButton value="tempo" sx={{ width: 1 / 4 }}>
            TEMPO
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <FormControl className={classes.radioGroupContainer}>
        <FormLabel id="trigger-type-radio-buttons-group-label">Discount Frequency</FormLabel>
        <RadioGroup
          aria-labelledby="trigger-type-radio-buttons-group-label"
          value={formState.discountDuration}
          onChange={(e) => handleDiscountFrequencyChange(e.target.value)}
        >
          <div>
            <FormControlLabel value="menu" control={<Radio />} label="A menu week" />
            <span className={classes.frequencyRadioHelperText}>
              Apply once to a selected menu week.
            </span>
          </div>
          <div>
            <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
            <span className={classes.frequencyRadioHelperText}>
              Discounts are assigned for a set duration
            </span>
          </div>
        </RadioGroup>
      </FormControl>
      <Divider className={classes.divider} />
      <div className={formState.discountDuration === 'menu' ? classes.fullWidth : classes.hidden}>
        <TextField
          fullWidth
          label="Menu"
          margin="normal"
          disabled={formState.discountDuration !== 'menu'}
          onChange={(e) => onMenuChange(e.target.value)}
          select
          size="large"
          value={formState.menuId || ''}
          variant="outlined"
          className={classes.activeMenu}
        >
          {formState.formData.menus.map((menu) => (
            <MenuItem key={menu.ends_at} value={menu.id}>
              {formattedMenu(menu.ends_at)}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div
        className={formState.discountDuration === 'recurring' ? classes.fullWidth : classes.hidden}
      >
        <TextField
          fullWidth
          label="Select the Duration"
          margin="normal"
          select
          size="medium"
          value={formState.durationInWeeks}
          variant="outlined"
          className={classes.durationInWeeks}
          onChange={(e) => actions.setFormState('durationInWeeks', e.target.value)}
          disabled={userCount > 0}
        >
          {Array.from({ length: 13 }, (_, i) => i + 1)
            .concat('For life/event-based')
            .map((num) => (
              <MenuItem key={num} value={num}>
                {num}
              </MenuItem>
            ))}
        </TextField>
      </div>
    </div>
  );
};

BaseAttributeSection.propTypes = {
  classes: PropTypes.object.isRequired,
  handleServiceTypeChange: PropTypes.func.isRequired,
  handleDiscountFrequencyChange: PropTypes.func.isRequired,
  onMenuChange: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
};

export default BaseAttributeSection;
