import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Grid from '@mui/material/Grid';

import AddonsAndOptins from './AddonsAndOptins';
import BasicInfo from './BasicInfo';
import Channel from './Channel';
import SaveChangesButton from '../shared/SaveChangesButton';
import ServingsAndPortions from './ServingsAndPortions';
import ProductType from './ProductType';

import { ACTIONS, reducer } from '../reducer';

const initialStateFromMeal = (meal) => ({
  formState: {
    autoAssign: meal.autoAssign,
    baseServingSize: meal.baseServingSize,
    category: meal.category,
    channelId: meal.channelId,
    chefId: meal.chefId,
    culinaryVersionNotes: meal.culinaryVersionNotes,
    description: meal.description,
    gtinAssignments: meal.gtinAssignments,
    maxOrderableQuantity: meal.maxOrderableQuantity,
    mealAddonId: meal.mealAddonId,
    mealLabel: meal.mealLabel,
    mealPlanId: meal.mealPlanId,
    mealTypeId: meal.mealTypeId,
    nfpTitle: meal.nfpTitle,
    optInIds: meal.optInIds,
    price: meal.price,
    productTypeId: meal.productTypeId,
    retailPackagingTypeId: meal.retailPackagingTypeId,
    status: meal.status,
    subtitle: meal.subtitle,
    title: meal.title,
    unitAmount: meal.unitAmount,
    unitName: meal.unitName,
  },
});

const BasicsTab = ({ classes, setDirty }) => {
  const { meal } = useSelector((state) => state.mealEditForm.meal);
  const [state, dispatch] = useReducer(reducer, initialStateFromMeal(meal));

  const handleFormState = (event) => {
    setDirty(true);

    const { name, value } = event.target;
    dispatch({ type: ACTIONS.UPDATE_FORM_STATE, payload: { name, value } });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  return (
    <>
      <Grid item xs={6}>
        <BasicInfo
          classes={classes}
          formState={state.formState}
          handleFormState={handleFormState}
          mealPlanId={meal.mealPlanId}
        />
      </Grid>
      <Grid item xs={6}>
        <ProductType
          classes={classes}
          formState={state.formState}
          handleFormState={handleFormState}
        />
        <ServingsAndPortions
          classes={classes}
          formState={state.formState}
          handleFormState={handleFormState}
        />
        <Channel classes={classes} formState={state.formState} handleFormState={handleFormState} />
        <AddonsAndOptins
          classes={classes}
          formState={state.formState}
          handleFormState={handleFormState}
        />
      </Grid>
      <Grid item xs={12}>
        <SaveChangesButton mealParams={state.formState} mealId={meal.id} />
      </Grid>
    </>
  );
};

BasicsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default BasicsTab;
