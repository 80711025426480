import { centsToDollars, dollarsToCents } from 'lib/helpers/money';
import { mapKeysDeep } from 'lib/helpers/objects';
import { snakeCase } from 'lodash';

export const convertBehavioralDiscountToFormState = (behavioralDiscount) => {
  const { valueCents, targetValueCents, durationInWeeks, ...filteredBehavioralDiscount } =
    behavioralDiscount;

  return {
    ...filteredBehavioralDiscount,
    durationInWeeks: durationInWeeks === null ? 'For life/event-based' : durationInWeeks,
    valueCents: centsToDollars(valueCents),
    targetValueCents: centsToDollars(targetValueCents),
  };
};

export const convertFormStateToParams = (formState, behavioralDiscountId = null) => {
  // Deconstruct anything that either isn't submitted (i.e. formData) or will get transformed further
  const {
    formData,
    formValidation,
    photoFile,
    rewardMenuSelectionIds,
    rewardProductTypeIds,
    targetValueCents,
    triggerMenuSelectionIds,
    valueCents,
    valuePercent,
    ...otherFormState
  } = formState;

  // Snakecase first because it doesn't play nicely with Arrays/Objects/Files
  const snakeCasedFormData = mapKeysDeep(otherFormState, (_value, key) => {
    return snakeCase(key);
  });

  const transformedFormData = {
    ...snakeCasedFormData,
    id: behavioralDiscountId,
    photo_file: photoFile,
    reward_menu_selection_ids: JSON.stringify(rewardMenuSelectionIds),
    reward_product_type_ids: JSON.stringify(rewardProductTypeIds),
    target_value_cents: dollarsToCents(formState.targetValueCents),
    trigger_menu_selection_ids: JSON.stringify(triggerMenuSelectionIds),
    value_cents: dollarsToCents(formState.valueCents),
    value_percent: parseInt(formState.valuePercent, 10),
  };

  return transformedFormData;
};
